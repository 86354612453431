import React from 'react';
import { Text } from '@paygreen/paygreen-ui';

const Quote = ({ children, colorTheme, ...rest }) => {
    return (
        <Text
            htmlTag="div"
            colorPallet="theme"
            colorTheme={colorTheme}
            hasBackground={true}
            radiusSize="sm"
            paddingTop="sm"
            paddingBottom="sm"
            paddingLateral="sm"
            marginBottom="md"
            {...rest}
        >
            {children}
        </Text>
    );
};

export default Quote;
