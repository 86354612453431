import React from 'react';
import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import {
    Grid,
    Card,
    Text,
    Button,
    Divider,
    ButtonGroup,
} from '@paygreen/paygreen-ui';
import Layout from '../components/Layout';
import Answer from '../components/Answer';
import AuthorSign from '../components/AuthorSign';
import LinkDispatcher from '../components/LinkDispatcher';
import SimilarBoard from '../components/SimilarBoard';
import SEO from '../components/seo';
import Banner from '../components/Banner';

const spaceArticle = 'md';

const QuestionPage = ({ data }) => {
    const intl = useIntl();
    const question = data.prismic.question;
    const elementColor =
        question.colorTheme === 'default' ? 'primary' : question.colorTheme;
    const gradient = question.colorTheme === 'default' ? 'brand' : 'theme';

    const {
        metaTitle,
        metaDescription,
        ogUrl,
        ogTitle,
        ogDescription,
        ogImage,
    } = data.prismic.question;

    const breadcrumb = [
        {
            url: '/',
            label: intl.formatMessage({ id: 'home.title' }),
        },
        {
            url: '/' + question.parentCategory.parentTheme._meta.uid + '/',
            label: question.parentCategory.parentTheme.breadcrumbLabel,
        },
        {
            url:
                '/' +
                question.parentCategory.parentTheme._meta.uid +
                '/' +
                question.parentCategory._meta.uid +
                '/',
            label: question.parentCategory.breadcrumbLabel,
        },
        {
            url: '#',
            label: question.breadcrumbLabel,
        },
    ];

    return (
        <Layout colorTheme={elementColor}>
            <SEO
                metaTitle={metaTitle}
                description={metaDescription}
                ogTitle={ogTitle}
                ogDescription={ogDescription}
                ogImage={ogImage}
                ogUrl={ogUrl}
            />

            <Banner
                gradient={gradient}
                theme={elementColor}
                breadcrumb={breadcrumb}
                name={question.title}
                description={question.description}
                titleStyles={{
                    textSize: 'lg',
                }}
            />

            <Grid
                justifyContent="center"
                childrenMargin="none"
                childrenMarginBig="none"
                childrenShiftSize="sm"
                isNegativeShift
                isReverseShift
            >
                <Card blockWidth="xl" colorTheme={elementColor} paddingTop="sm">
                    <Answer content={question.body} colorTheme={elementColor} />

                    <AuthorSign
                        author={question.author}
                        date={question.date}
                        paddingLateral={spaceArticle}
                    />
                </Card>
            </Grid>

            <SimilarBoard
                colorTheme={elementColor}
                questionID={question._meta.id}
            />

            <Divider
                text={intl.formatMessage({ id: 'contactus.divider' })}
                waveStyle="right"
                marginBottom="none"
                colorTheme={elementColor}
            />

            <Grid
                justifyContent="center"
                childrenMargin="none"
                childrenMarginBig="none"
            >
                <Card hasBackground={false}>
                    <Text
                        colorTheme={elementColor}
                        marginBottom="sm"
                        align="center"
                    >
                        {intl.formatMessage({ id: 'contactus.text' })}
                    </Text>

                    <ButtonGroup>
                        <LinkDispatcher url="https://paygreen.io/contact/">
                            <Button
                                data-testid="contactus-btn"
                                colorTheme={elementColor}
                            >
                                {intl.formatMessage({ id: 'contactus.btn' })}
                            </Button>
                        </LinkDispatcher>
                    </ButtonGroup>
                </Card>
            </Grid>
        </Layout>
    );
};

export default QuestionPage;

export const query = graphql`
    query($uid: String!) {
        allSitePage {
            edges {
                node {
                    path
                }
            }
        }
        prismic {
            question(lang: "fr-fr", uid: $uid) {
                breadcrumbLabel
                title
                description
                date
                colorTheme
                metaTitle
                metaDescription
                canonicalUrl
                ogUrl
                ogTitle
                ogDescription
                ogImage
                _meta {
                    id
                }
                author {
                    ... on PRISMIC_Author {
                        lastname
                        firstname
                        position
                        profileImage
                    }
                }
                parentCategory {
                    ... on PRISMIC_Category {
                        name
                        breadcrumbLabel
                        colorTheme
                        _meta {
                            uid
                        }
                        parentTheme {
                            ... on PRISMIC_Theme {
                                name
                                breadcrumbLabel
                                icon
                                _meta {
                                    uid
                                }
                            }
                        }
                    }
                }
                body {
                    ... on PRISMIC_QuestionBodyQuote {
                        type
                        label
                        primary {
                            quoteContent
                            quoteUid
                        }
                    }
                    ... on PRISMIC_QuestionBodyText {
                        type
                        label
                        primary {
                            textContent
                            textUid
                        }
                    }
                    ... on PRISMIC_QuestionBodyTable {
                        type
                        label
                        primary {
                            tableContent {
                                ... on PRISMIC_Table {
                                    title
                                    alternative
                                    colorTheme
                                    columns {
                                        colDescription
                                        colId
                                        colName
                                    }
                                    rows {
                                        col1
                                        col2
                                        col3
                                        col4
                                    }
                                }
                            }
                        }
                    }
                    ... on PRISMIC_QuestionBodyCtas {
                        type
                        label
                        fields {
                            ctaLink {
                                _linkType
                                ... on PRISMIC_Category {
                                    parentTheme {
                                        ... on PRISMIC_Theme {
                                            _meta {
                                                uid
                                            }
                                        }
                                    }
                                    _meta {
                                        uid
                                    }
                                }
                                ... on PRISMIC_Theme {
                                    _meta {
                                        uid
                                    }
                                }
                                ... on PRISMIC_Question {
                                    _meta {
                                        uid
                                    }
                                    parentCategory {
                                        ... on PRISMIC_Category {
                                            _meta {
                                                uid
                                            }
                                            parentTheme {
                                                ... on PRISMIC_Theme {
                                                    _meta {
                                                        uid
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                ... on PRISMIC__ExternalLink {
                                    url
                                    _linkType
                                }
                                ... on PRISMIC__FileLink {
                                    _linkType
                                    url
                                }
                            }
                            ctaTitle
                            ctaUid
                            ctaUrl
                        }
                    }
                }
            }
        }
    }
`;
