import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import {
    Divider,
    Card,
    Title,
    Text,
    Grid,
    ButtonGroup,
    Button,
} from '@paygreen/paygreen-ui';
import { findSimilar } from '../utils/prismicQueries';
import LinkDispatcher from './LinkDispatcher';
import { BuildDateContext } from './Layout';

const margin = 'sm';
const cardSize = 'sm';

const SimilarBoard = ({ questionID, colorTheme }) => {
    const buildDateContext = useContext(BuildDateContext);

    const intl = useIntl();

    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        findSimilar(questionID, buildDateContext).then(results => {
            setQuestions(results);
        });
    }, [questionID, buildDateContext]);

    return questions.length ? (
        <>
            <Divider
                text={intl.formatMessage({ id: 'similarboard.title' })}
                colorTheme={colorTheme}
                marginTop="lg"
                marginBottom="none"
            />

            <Grid justifyContent="center">
                {questions.map(({ data, uid }, index) => {
                    const elementColor =
                        data['color-theme'] === 'default'
                            ? 'primary'
                            : data['color-theme'];

                    return (
                        <Card
                            key={index}
                            borderTop={
                                data['color-theme'] === 'default'
                                    ? 'brand'
                                    : 'theme'
                            }
                            colorTheme={elementColor}
                            blockWidth={cardSize}
                        >
                            <Text
                                htmlTag="span"
                                marginTop={cardSize}
                                marginBottom={cardSize}
                                marginLateral={cardSize}
                                colorPallet="theme"
                                colorTheme={elementColor}
                                textSize="xxs"
                                hasUppercase={true}
                            >
                                {
                                    data['parent-category'].data['parent-theme']
                                        .data.name
                                }
                            </Text>

                            <Title
                                htmlTag="h3"
                                textSize="md"
                                marginTop={cardSize}
                                marginBottom="xs"
                                marginLateral={cardSize}
                            >
                                {data.title}
                            </Title>

                            <Text
                                marginTop="xs"
                                marginBottom={cardSize}
                                marginLateral={cardSize}
                                textSize="sm"
                            >
                                {data.description}
                            </Text>

                            <ButtonGroup
                                marginBottom={margin}
                                marginLateral={margin}
                            >
                                <LinkDispatcher
                                    url={
                                        '/' +
                                        data['parent-category'].data[
                                            'parent-theme'
                                        ].uid +
                                        '/' +
                                        data['parent-category'].uid +
                                        '/' +
                                        uid +
                                        '/'
                                    }
                                >
                                    <Button
                                        colorTheme={elementColor}
                                        buttonSize="sm"
                                    >
                                        {intl.formatMessage({ id: 'go.to' })}
                                    </Button>
                                </LinkDispatcher>
                            </ButtonGroup>
                        </Card>
                    );
                })}
            </Grid>
        </>
    ) : null;
};

SimilarBoard.propTypes = {
    questionID: PropTypes.string.isRequired,
};

SimilarBoard.defaultProps = {};

export default SimilarBoard;
