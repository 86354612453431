const linkResolver = (doc, allLinks) => {
    if (allLinks) {
        const foundDocument = allLinks.find(p => {
            return p.context ? p.context.uid === doc.uid : null;
        });

        if (foundDocument) {
            return foundDocument.path;
        }
    }

    return '.';
};

export default linkResolver;
