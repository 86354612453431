import React from 'react';
import { ButtonGroup, Button } from '@paygreen/paygreen-ui';
import LinkDispatcher from './LinkDispatcher';
import getUrlFromLink from '../utils/getUrlFromLink';

const Ctas = ({ content, colorTheme, ...rest }) => (
    <ButtonGroup align="center" marginBottom="sm" hasResetedMargins={false}>
        {content.fields.map((cta, index) => {
            let url = cta.ctaUrl;

            if (!url) {
                url = getUrlFromLink(cta.ctaLink);
            }

            return (
                <LinkDispatcher url={url} key={index}>
                    <Button colorTheme={colorTheme}>{cta.ctaTitle}</Button>
                </LinkDispatcher>
            );
        })}
    </ButtonGroup>
);

export default Ctas;
