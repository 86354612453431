/**
 * Allow to get the great url from content link
 * @param {Object} link Link from prismic
 */
const getUrlFromLink = link => {
    if (link) {
        switch (link._linkType) {
            case 'Link.web':
                return link.url;

            case 'Link.file':
                return link.url;

            case 'Link.document':
                switch (link.__typename) {
                    case 'PRISMIC_Theme':
                        return '/' + link._meta.uid + '/';
                    case 'PRISMIC_Category':
                        return (
                            '/' +
                            link.parentTheme._meta.uid +
                            '/' +
                            link._meta.uid +
                            '/'
                        );
                    case 'PRISMIC_Question':
                        return (
                            '/' +
                            link.parentCategory.parentTheme._meta.uid +
                            '/' +
                            link.parentCategory._meta.uid +
                            '/' +
                            link._meta.uid +
                            '/'
                        );
                    default:
                        break;
                }
                break;
            default:
                break;
        }
    }
    return null;
};

export default getUrlFromLink;
