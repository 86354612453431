import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { InternalGrid, Image, Text } from '@paygreen/paygreen-ui';
import convertENToFR from '../utils/dateConverter';

const AuthorSign = ({ author, date, ...rest }) => {
    if (!author) return;

    const profileImage = author.profileImage;
    const firstname = author.firstname;
    const lastname = author.lastname;
    const position = author.position;

    return (
        <Text
            htmlTag="div"
            marginTop="md"
            paddingTop="sm"
            paddingBottom="sm"
            hasBackground
            {...rest}
        >
            <InternalGrid justifyContent="flex-start" flexWrap="nowrap">
                {profileImage && profileImage.url ? (
                    <Image blockWidth="xs" imageType="picture" isCircle>
                        <img
                            height="60"
                            width="60"
                            src={profileImage.url}
                            alt={
                                profileImage.alt
                                    ? profileImage.alt
                                    : firstname && lastname
                                    ? firstname + ' ' + lastname
                                    : null
                            }
                        />
                    </Image>
                ) : null}

                <div>
                    {firstname && lastname ? (
                        <Text
                            textSize="sm"
                            marginTop="none"
                            marginLateral={profileImage ? 'xs' : 'none'}
                        >
                            {firstname + ' ' + lastname}
                        </Text>
                    ) : null}

                    {position ? (
                        <Text
                            hasUppercase={true}
                            textSize="xxs"
                            colorWab="grey40"
                            marginLateral={profileImage ? 'xs' : 'none'}
                        >
                            {position}
                        </Text>
                    ) : null}

                    {date ? (
                        <Text
                            colorWab="grey40"
                            textSize="xs"
                            marginLateral={profileImage ? 'xs' : 'none'}
                        >
                            <FormattedMessage
                                id="blog.date.the"
                                values={{
                                    date: convertENToFR(date),
                                }}
                            />
                        </Text>
                    ) : null}
                </div>
            </InternalGrid>
        </Text>
    );
};

AuthorSign.propTypes = {
    author: PropTypes.object,
    date: PropTypes.string,
};

export default AuthorSign;
