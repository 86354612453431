import React, { useContext } from 'react';
import { Text } from '@paygreen/paygreen-ui';
import RichText from '../utils/richTextPayGreen';
import Quote from './Quote';
import Ctas from './Ctas';
import Table from './Table';
import linkResolver from '../utils/linkResolver';
import { CommonDataContext } from '../contexts/CommonDataContext';

const marginLateral = 'md';

const Answer = ({ content, colorTheme }) => {
    const items = [];
    const commonDataContext = useContext(CommonDataContext);

    content.forEach((element, index) => {
        switch (element.type) {
            case 'text':
                if (element.primary.textContent) {
                    items.push(
                        <Text
                            key={index}
                            colorTheme={colorTheme}
                            htmlTag="div"
                            marginTop="sm"
                            marginBottom="sm"
                            marginLateral={marginLateral}
                        >
                            <RichText
                                render={element.primary.textContent}
                                linkResolver={e =>
                                    linkResolver(e, commonDataContext)
                                }
                                colorTheme={colorTheme}
                            />
                        </Text>,
                    );
                }
                break;
            case 'ctas':
                items.push(
                    <Text
                        key={index}
                        htmlTag="div"
                        marginTop="sm"
                        marginBottom="sm"
                        marginLateral={marginLateral}
                    >
                        <Ctas content={element} colorTheme={colorTheme} />
                    </Text>,
                );
                break;
            case 'quote':
                if (element.primary.quoteContent) {
                    items.push(
                        <Quote
                            key={index}
                            colorTheme={colorTheme}
                            marginLateral={marginLateral}
                        >
                            <RichText
                                render={element.primary.quoteContent}
                                linkResolver={e =>
                                    linkResolver(e, commonDataContext)
                                }
                                colorTheme={colorTheme}
                            />
                        </Quote>,
                    );
                }
                break;
            case 'table':
                if (element.primary.tableContent) {
                    items.push(
                        <Table
                            key={index}
                            content={element.primary.tableContent}
                        />,
                    );
                }
                break;
            default:
                break;
        }
    });

    return <>{items}</>;
};

export default Answer;
