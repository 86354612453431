import React from 'react';
import {
    Table as PGTable,
    TableRow,
    TableCell,
    InternalGrid,
} from '@paygreen/paygreen-ui';
import RichText from '../utils/richTextPayGreen';

const Table = ({ content }) => {
    if (!content) {
        return null;
    }

    return (
        <InternalGrid justifyContent="center" childrenMarginLateral="sm">
            <PGTable colorTheme={content.colorTheme} hasLargeFirstColumn>
                <TableRow isMain={true}>
                    <TableCell>
                        {content.title ? RichText.asText(content.title) : ''}
                    </TableCell>
                    {content.columns.map(cell => {
                        return (
                            <TableCell key={cell.colId}>
                                <span>{RichText.asText(cell.colName)}</span>
                                {cell.colDescription ? (
                                    <i>
                                        {RichText.asText(cell.colDescription)}
                                    </i>
                                ) : null}
                            </TableCell>
                        );
                    })}
                </TableRow>
                {content.rows.map((row, index) => (
                    <TableRow key={index}>
                        {row.col1 ? (
                            <TableCell>{RichText.asText(row.col1)}</TableCell>
                        ) : null}
                        {row.col2 ? (
                            <TableCell>{RichText.asText(row.col2)}</TableCell>
                        ) : null}
                        {row.col3 ? (
                            <TableCell>{RichText.asText(row.col3)}</TableCell>
                        ) : null}
                        {row.col4 ? (
                            <TableCell>{RichText.asText(row.col4)}</TableCell>
                        ) : null}
                    </TableRow>
                ))}
            </PGTable>
        </InternalGrid>
    );
};

export default Table;
